body {
  background: #f2f3f8 !important;
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root{
  width: 200px;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 200px;

}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b0b0b0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}